import React, { useEffect, useState } from 'react'
import styles from './galleryreview.module.css'
import { NavLink } from 'react-router-dom'
import GalleryCard from './GalleryCard'
import project1 from "../../assets/projects/logo1.png";
import project2 from "../../assets/projects/gafaterp1.avif";
import project3 from "../../assets/projects/gafaterp2.avif";
import jambo from "../../assets/projects/abcerp.jpg";
import jambo1 from "../../assets/projects/abcerp1.webp";
import jambo2 from "../../assets/projects/abcerp2.webp";
import jigdan from "../../assets/projects/wabi.png";
import jigdan1 from "../../assets/projects/jambo1.jpg";
import jigdan2 from "../../assets/projects/jambo2.jpg";
import honeymoon from "../../assets/projects/honeymoon.jpg";
import Title from '../title/Title'
import axios from 'axios';
import { BACKENDURL } from '../helper/Urls';

const GalleryReview = () => {
  const scrollTop=()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  // const [galleryData, setgalleryData] = useState([]);

  // useEffect(() => {
  //   const fetchProjectData = async () => {
  //     try {
  //       const res = await axios.get(`${BACKENDURL}/project/all`);
  //       setgalleryData(res.data.all);
  //     } catch (error) {
  //       console.error('Error fetching slide data:', error);
  //     }
  //   };

  //   fetchProjectData();
  // }, []);
const galleryData=[
    {
      "id": 1,
      "companyName": "Gafat Security Service Erp System",
      "description": "Our company specializes in developing customized ERP systems, websites, and related solutions, along with providing expert consulting services. Currently, we are building a tailored ERP system for Gafat Security Service Company, designed to streamline operations, enhance efficiency, and improve overall management. Our solutions integrate advanced features to meet the unique needs of security service providers, ensuring seamless workflow automation and data management.",
      "type":'ERP',
      "link":'https://www.zaahirahtravels.com',
      "file":'https://www.zaahirahtravels.com',
      "pic": project1,
      "pic1": project2,
      "pic2": project3,
    },
    {
      "id": 2,
      "companyName": "ABC Security Service Erp System",
      "description": "Our company specializes in developing customized ERP systems, websites, and related solutions, along with providing expert consulting services. Currently, we are building a tailored ERP system for ABC Security Service Company, designed to streamline operations, enhance efficiency, and improve overall management. Our solutions integrate advanced features to meet the unique needs of security service providers, ensuring seamless workflow automation and data management.",
      "type":'ERP',
      "link":'https://www.zaahirahtravels.com',
      "file":'https://www.zaahirahtravels.com',
      "pic": jambo,
      "pic1": jambo1,
      "pic2": jambo2,
    }
    ,
    {
      "id": 3,
      "companyName": "Wabi Company Profile",
      "description": "Our company is creating a comprehensive company profile for Wabi Security and Cleaning Service, a trusted provider of top-tier security and cleaning services. The profile will highlight Wabi’s dedication to delivering reliable, professional services, emphasizing their expertise in both security and cleaning operations. Our work includes crafting a detailed presentation that showcases their services, team, and mission, ensuring a polished and professional representation of the company’s values and capabilities.",
      "type":'Website',
      "link":'https://www.zaahirahtravels.com',
      "file":'https://www.zaahirahtravels.com',
      "pic": jigdan,
      "pic1": jambo1,
      "pic2": jambo2,
    }
  ]
  return (
    <div className={styles.cont}>
          <Title mainTitle={'Recent Projects'} subTitle={"Photos from our Experience"}/>
          <div className={styles.lists}>
            {galleryData.slice(0, 3).map((l)=><GalleryCard key={l.id} {...l}/>)}
          </div>
          <div className={styles.moreservice}><NavLink to={'/projects'} onClick={scrollTop}>More Projects</NavLink></div>
        </div>
  )
}

export default GalleryReview