import React, { useEffect, useState } from 'react'
import styles from "./gallery.module.css";
import PageIntro from '../components/cards/PageIntro';
import zimg from '../assets/gallery.jpg'
import GalleryCard from '../components/cards/GalleryCard';
import project1 from "../assets/projects/logo1.png";
import project2 from "../assets/projects/gafaterp1.avif";
import project3 from "../assets/projects/gafaterp2.avif";
import project4 from "../assets/projects/cleaning4.jpg";
import jambo from "../assets/projects/abcerp.jpg";
import jambo1 from "../assets/projects/abcerp1.webp";
import jambo2 from "../assets/projects/abcerp2.webp";
import jambo3 from "../assets/projects/jigdan3.jpg";
import jigdan from "../assets/projects/wabi.png";
import jigdan1 from "../assets/projects/jambo1.jpg";
import jigdan2 from "../assets/projects/jambo2.jpg";
import jigdan3 from "../assets/projects/jambo3.jpg";
import honeymoon from "../assets/projects/honeymoon.jpg";
import axios from 'axios';
import { BACKENDURL } from '../components/helper/Urls';

import Title from '../components/title/Title';
// import ModelShow from '../components/ModelShow';

const Gallerys = () => {

  useEffect(() => {
    document.title = ' Projects';
  }, []);
  // const [galleryData, setgalleryData] = useState([]);

  // useEffect(() => {
  //   const fetchProjectData = async () => {
  //     try {
  //       const res = await axios.get(`${BACKENDURL}/project/all`);
  //       setgalleryData(res.data.all);
  //       console.log(res.data.all);
  //     } catch (error) {
  //       console.error('Error fetching slide data:', error);
  //     }
  //   };

  //   fetchProjectData();

  //   // Clean-up function
  //   return () => {
  //     // Any clean-up code can go here
  //   };
  // }, []); 
  const galleryData=[
      {
        "id": 1,
        "companyName": "Gafat Security Service Erp System",
        "description": "Our company specializes in developing customized ERP systems, websites, and related solutions, along with providing expert consulting services. Currently, we are building a tailored ERP system for Gafat Security Service Company, designed to streamline operations, enhance efficiency, and improve overall management. Our solutions integrate advanced features to meet the unique needs of security service providers, ensuring seamless workflow automation and data management.",
        "type":'ERP',
        "link":'https://www.zaahirahtravels.com',
        "file":'https://www.zaahirahtravels.com',
        "pic": project1,
        "pic1": project2,
        "pic2": project3,
      },
      {
        "id": 2,
        "companyName": "ABC Security Service Erp System",
        "description": "Our company specializes in developing customized ERP systems, websites, and related solutions, along with providing expert consulting services. Currently, we are building a tailored ERP system for ABC Security Service Company, designed to streamline operations, enhance efficiency, and improve overall management. Our solutions integrate advanced features to meet the unique needs of security service providers, ensuring seamless workflow automation and data management.",
        "type":'ERP',
        "link":'https://www.zaahirahtravels.com',
        "file":'https://www.zaahirahtravels.com',
        "pic": jambo,
        "pic1": jambo1,
        "pic2": jambo2,
      }
      ,
      {
        "id": 3,
        "companyName": "Wabi Company Profile",
        "description": "Our company is creating a comprehensive company profile for Wabi Security and Cleaning Service, a trusted provider of top-tier security and cleaning services. The profile will highlight Wabi’s dedication to delivering reliable, professional services, emphasizing their expertise in both security and cleaning operations. Our work includes crafting a detailed presentation that showcases their services, team, and mission, ensuring a polished and professional representation of the company’s values and capabilities.",
        "type":'Website',
        "link":'https://www.zaahirahtravels.com',
        "file":'https://www.zaahirahtravels.com',
        "pic": jigdan,
        "pic1": jambo1,
        "pic2": jambo2,
      }
    ]

  const [filterType,setFilterType]=useState('')
  

  let filteredItems;
  if (filterType !== '') {
    filteredItems = galleryData.filter(item => item.type === filterType);
  } else {
    filteredItems = galleryData;
  }

  const types=Array.from(
    new Set(
      galleryData.map(project => project.type)  
    )
  )
  
  return (
    <>
      <PageIntro text={'Projects'} img={zimg}/>
      <div className={styles.cont}>
        <div className={styles.box}>
          <Title mainTitle={'Photos from our Experience and Products'} subTitle={''}/>
          <div className={styles.searchbox}>
            <span className={styles.searchlinks} onClick={()=>setFilterType('')} style={{background:filterType===''?'var(--fcolor)':'white',color:filterType===''?'white':'var(--fcolor)'}}>All</span>
            {types.map((l,k)=>
            <span className={styles.searchlinks} onClick={()=>setFilterType(l)} style={{background:filterType===l?'var(--fcolor)':'white',color:filterType===l?'white':'var(--fcolor)'}} key={k}>{l}</span>
            )}
          </div>
          <div className={styles.lists}>
          {
          filteredItems.map((l)=><GalleryCard key={l.id} {...l}/>)
          }
          </div>
        </div>
      </div>
    </>
  )
}

export default Gallerys