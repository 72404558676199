import React, { useEffect, useState } from 'react'
import styles from './productslide.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay,Navigation  } from 'swiper/modules';
import { MdCameraswitch, MdSecurity } from "react-icons/md";
import { IoMdAlarm } from "react-icons/io";
import { BACKENDURL } from '../../components/helper/Urls';
import axios from 'axios';
import { Image } from "antd";
import { NavLink } from "react-router-dom";
import pic from '../../assets/services/technology.jpg'
import pic4 from '../../assets/services/abcerp2.webp'
import pic3 from '../../assets/services/webdesign.webp'
import pic2 from '../../assets/services/It.webp'
import pic6 from '../../assets/services/ittrain.jpg'
import pic5 from '../../assets/services/ui.jpg'
import {FaBriefcase, FaCarAlt, FaChalkboardTeacher, FaCoins, FaShieldAlt, FaUsers } from 'react-icons/fa'
import Title from '../title/Title';

const ProductSlide = () => {

  useEffect(() => {
    document.title = ' Service';
  }, []);
  // const [servies, setservies] = useState([]);

  // useEffect(() => {
  //   const fetchServiceData = async () => {
  //     try {
  //       const res = await axios.get(`${BACKENDURL}/service/all`);
  //       setservies(res.data.all);
  //     } catch (error) {
  //       console.error('Error fetching slide data:', error);
  //     }
  //   };
  //   fetchServiceData();
  // }, []);
      const servies = [
               {
                 "id": 1,
                 "title": "Technology solution",
                 "pic": pic,
                 "description": "We provide cutting-edge technology solutions designed to streamline operations, enhance efficiency, and drive business growth. From website development and ERP systems to customized software and IT consulting, our solutions are tailored to meet the unique needs of your business. Whether you're looking to improve workflows, automate processes, or strengthen your digital presence, we deliver scalable, secure, and innovative technology that helps you stay ahead in a competitive market."
               },
               {
                 "id": 2,
                 "title": "It Service",
                 "pic": pic2,
                 "description": "We offer a full range of IT services designed to keep your business running smoothly and securely. From system integration and cloud solutions to network security and IT support, we provide reliable and scalable solutions tailored to your needs. Our team ensures seamless technology operations, minimizing downtime and enhancing efficiency. Whether you need infrastructure management, cybersecurity, or ongoing tech support, we deliver innovative IT services to help your business thrive in the digital era."
               },
               {
                 "id": 3,
                 "title": "Web & App Design",
                 "pic": pic3,
                 "description": "We create visually stunning, user-friendly websites and mobile applications that deliver seamless digital experiences. Our designs are not only aesthetically appealing but also optimized for performance, responsiveness, and functionality. Whether you need a sleek business website, an engaging e-commerce platform, or a feature-rich mobile app, we blend creativity with technology to bring your vision to life."
               },
               {
                "id": 4,
                "title": "ERP system development",
                "pic": pic4,
                "description": "We create visually stunning, user-friendly websites and mobile applications that deliver seamless digital experiences. Our designs are not only aesthetically appealing but also optimized for performance, responsiveness, and functionality. Whether you need a sleek business website, an engaging e-commerce platform, or a feature-rich mobile app, we blend creativity with technology to bring your vision to life."
              },
              {
                "id": 5,
                "title": "Ui/UX Design",
                "pic": pic5,
                "description": "Our UI/UX design service focuses on creating intuitive, visually appealing, and user-friendly digital experiences. We prioritize user needs and behavior, ensuring seamless interactions across websites and mobile apps. Through research-driven design, prototyping, and usability testing, we craft interfaces that not only look great but also improve user engagement and satisfaction. Our goal is to enhance the overall user journey, driving higher conversions and fostering lasting relationships with your audience."
              },
              {
                "id": 6,
                "title": "IT Training & User Support",
                "pic": pic6,
                "description": "Our IT Training & User Support service ensures that your team is equipped with the knowledge and skills needed to maximize the potential of your software and IT systems. We offer customized training programs tailored to your specific software solutions, whether it's ERP systems, CRM tools, or other applications. Additionally, our user support service provides ongoing assistance, troubleshooting, and technical help to ensure smooth operation. With responsive help desk support and hands-on training, we empower your team to work efficiently and resolve issues quickly, minimizing downtime and boosting productivity."
              },
             ];
      const [currentSlideIds, setCurrentSlideIds] = useState([]);
      const [middleItemId, setMiddleItemId] = useState(null);

      const handleSlideChange = (swiper) => {
        const { activeIndex, slides } = swiper;
        const visibleSlideIds = slides
          .filter((slide, index) => index >= activeIndex && index < activeIndex + swiper.params.slidesPerView)
          .map((slide) => parseInt(slide.getAttribute('data-id'), 10));
    
        setCurrentSlideIds(visibleSlideIds);
      };
    
      useEffect(() => {
        if (currentSlideIds.length > 0) {
          const middleIndex = Math.floor(currentSlideIds.length / 2);
          setMiddleItemId(currentSlideIds[middleIndex]);
        } else {
          setMiddleItemId(null);
        }
      }, [currentSlideIds]);
    

  // const getCurrentVisibleItemIds = () => {
  //   return currentSlideIds;
  // };

  return (
    <div className={styles.cont}>
          <Title mainTitle={'Featured Services'} subTitle={"CHECK OUT OUR PRODUCTS AND SERVICE"}/>
          <div className={styles.list}>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            onSlideChange={handleSlideChange} 
            loop={true}
            breakpoints={{
              '@0.00': {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              '@0.50': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '@0.75': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              '@1.00': {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              '@1.50': {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
            modules={[Autoplay,Navigation ]}
        className={styles.myswiper}
      >
        {servies.map((l)=>(
            <SwiperSlide className={styles.slide} key={l.id} data-id={l.id}>
            <div className={middleItemId===l.id ?styles.activeitembox:styles.itembox} >
                <Image width={120} height={120} src={l.pic} alt={l.name}/>
                {/* <div className={styles.imgbox}><Image className={styles.zimg} src={`${BACKENDURL}/uploads/photo/${l.attachment}`} alt={l.title}/></div> */}
                <div className={styles.itemtxt}>
                    <NavLink state={l} className={styles.button} to={`/service/${l.title}`}>{l.title}</NavLink>
                </div>
            </div>
        </SwiperSlide>
        ))}
        {/* <div className={styles.customNavigation}>
              <FaAngleLeft className={styles.navbtnl}/>
              <FaAngleRight className={styles.navbtnr}/>
          </div> */}
      </Swiper>

          </div>
    </div>
  )
}

export default ProductSlide