import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import pic from '../../assets/sliders/slider1.jpeg'
import pic2 from '../../assets/sliders/slider2.jpg'
import pic3 from '../../assets/sliders/slider3.jpg'
import pic4 from '../../assets/sliders/slider4.jpg'
import pic5 from '../../assets/sliders/slider5.jpg'
import pic6 from '../../assets/sliders/slider6.jpg'
import pic7 from '../../assets/sliders/slider7.jpg'
import pic8 from '../../assets/sliders/slider8.jpg'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styles from './introswiper.module.css';
import { BACKENDURL } from '../helper/Urls';

const IntroSwiper = () => {
  // const [slideData, setSlideData] = useState([]);

  // useEffect(() => {
  //   const fetchSlideData = async () => {
  //     try {
  //       const res = await axios.get(`${BACKENDURL}/slider/all`);
  //       setSlideData(res.data.all);
  //     } catch (error) {
  //       console.error('Error fetching slide data:', error);
  //     }
  //   };
  //   fetchSlideData();
  // }, []); // Empty dependency array to run effect only once on mount
const slideData=[
          {
            "id": 1,
            "description": "Our tech solutions company empowers businesses to thrive in the digital age. We offer a comprehensive suite of services.",
            "pic": pic7
          },
          {
            "id": 2,
            "pic": pic2,
            "description": "Our tech solutions company collaborates closely with clients to understand their needs and develop customized solutions that drive measurable results."
          },
          {
            "id": 3,
            "pic": pic3,
            "description": "We provide practical, effective solutions that solve real-world business challenges and improve operational efficiency."
          },
          {
            "id": 4,
            "pic": pic4,
            "description": "Our tech solutions company offers a complete range of services to meet all your technology needs."
          },
          {
            "id": 5,
            "pic": pic5,
            "description": "Our tech solutions company offers deep industry knowledge and technical expertise to deliver best-in-class solutions."
          },
          {
            "id": 6,
            "pic": pic6,
            "description": "Our tech solutions company is dedicated to the success of our clients."
          },
          {
            "id": 7,
            "pic": pic,
            "description": "Our tech solutions company tackles complex business challenges with innovative and effective technology solutions, helping our clients overcome obstacles and achieve sustainable growth."
          },
          
          {
            "id": 8,
            "pic": pic8,
            "description": "Our tech solutions company builds scalable and flexible solutions that grow with your business."
          },
          
        ]
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      grabCursor={true}
      loop={true}
      autoplay={{
        delay: 5000,
      }}
      modules={[Autoplay]}
      className={styles.swiper}
    >
      {slideData.map((slide, index) => (
        <SwiperSlide className={styles.swiperslide} key={index}>
          {/* <img src={`${BACKENDURL}/uploads/photo/${slide.attachment}`} alt={slide.alt} /> */}
          <img src={slide.pic} alt={slide.alt} />
          <span>{slide.description}</span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default IntroSwiper;