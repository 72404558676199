import React from 'react'
import styles from './ServiceDesc.module.css'
const ServiceDesc = () => {
  return (
    <div className={styles.cont}>
        <div className={styles.title}>Our company has two years of experience in developing websites, systems, ERP solutions, and other related services.</div>
        <div className={styles.description}><p>Our company specializes in developing high-quality websites, systems, and ERP solutions tailored to meet the unique needs of businesses. With two years of experience, we have successfully delivered innovative and efficient digital solutions that enhance operational efficiency and business growth. Our expertise includes website development, custom software solutions, and comprehensive ERP systems designed to streamline business processes and improve overall productivity.</p>
      <p>In addition to development services, we provide consulting to help businesses optimize their digital transformation strategies. Whether you need a corporate website, a robust enterprise system, or an ERP solution to manage your operations seamlessly, we are committed to delivering reliable and scalable solutions. Our goal is to empower businesses with cutting-edge technology that drives success and long-term growth.</p>
</div>
    </div>
  )
}

export default ServiceDesc