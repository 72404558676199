import React, { useEffect } from "react";
import styles from "./about.module.css";
import PageIntro from "../components/cards/PageIntro";
import zimg from "../assets/gallery1.jpg";
import zimg1 from "../assets/product1.jpg";
import brand1 from "../assets/brands/tele.png";
import brand2 from "../assets/brands/siltet.jpg";
import brand3 from "../assets/brands/yegra.png";
import brand5 from "../assets/brands/cpanel-logo.svg";
import brand4 from "../assets/brands/safa.png";
import video3 from "../assets/projects/video3.mp4";
import video2 from "../assets/projects/Video2.mp4";
import zimg2 from "../assets/about2.jpg";
import StaffReview from "../components/cards/StaffReview";
import Title from "../components/title/Title";
import PartnerSlide from "../components/cards/PartnerSlide";
// import {FaPlus, FaTimes} from 'react-icons/fa'

const About = () => {
  useEffect(() => {
    document.title = " About Us";
  }, []);

  // const Awards=[
  //   {id:1,pic:zimg},
  //   {id:2,pic:zimg1},
  //   {id:3,pic:zimg2},
  //   {id:4,pic:zimg2},
  //   {id:5,pic:zimg1},
  // ]

  const Partners=[
    {id:1,pic:brand1},
    {id:2,pic:brand2},
    {id:3,pic:brand3},
    {id:4,pic:brand4},
    {id:5,pic:brand5},
  ]

  // const [viewAward,setViewAward]=useState(0)

  return (
    <>
      {/* <PageIntro text={"About Us"} img={zimg} /> */}
      <div className={styles.cont}>
        <div className={styles.box}>
          {/* <span className={styles.awardtitle}>Awards and Certficates</span>
          <div className={styles.titleline}>
              <span></span>
              <span></span>
              <span></span>
            </div> */}
        {/* <div className={styles.awardscont}>
            {Awards.map((l)=>(
            <div className={l.id===viewAward ?styles.viewawardbox:styles.awardbox} key={l.id}>
              <div className={l.id===viewAward ?styles.viewblackbox:styles.blackbox}>
              <span onClick={()=>setViewAward(l.id)} className={styles.viewawardbtn}><FaPlus/></span>
              <img src={l.pic} alt="award" style={{borderRadius:l.id===viewAward?'':'50%'}} className={styles.awardimg}/>
              <span className={styles.closeaward} style={{display:l.id===viewAward?'flex':'none'}} onClick={()=>setViewAward(0)}><FaTimes/></span>
              </div>
            </div>))}
          </div> */}
          
          <div className={styles.lists}>
            <div className={styles.contlist}></div>
            <img className={styles.imgbox} src={zimg1} alt={'about'} />
            <div className={styles.textbox}>
              <span className={styles.detailtitle}>Our History</span>
              <span className={styles.detaildes}>
              Over the past two years, our company has grown into a trusted provider of digital solutions, specializing in website development, system integration, and ERP solutions. From the beginning, our goal has been to help businesses optimize their operations through technology. We have worked with various clients across different industries, delivering customized solutions that improve efficiency and drive growth. Through dedication and innovation, we have established a strong reputation for delivering high-quality and reliable digital services.
              </span>
            </div>
          </div>

          <div className={styles.lists}>
            <div className={styles.contlist2}></div>
            <div className={styles.textbox}>
              <span className={styles.detailtitle}>Our Mission</span>
              <span className={styles.detaildes}>
              <ul className={styles.detaildes}>
  <li>Deliver high-quality, innovative, and reliable digital solutions tailored to business needs.</li>
  <li>Enhance operational efficiency through customized ERP systems and software solutions.</li>
  <li>Ensure customer satisfaction by offering scalable, secure, and user-friendly technology solutions.</li>
  <li>Empower businesses with digital tools that drive growth, efficiency, and success.</li>
  <li>Build long-term partnerships by providing exceptional support and maintenance services.</li>
  <li>Continuously improve and adopt the latest technologies to stay ahead in the industry.</li>
</ul></span>
            </div>
            <img className={styles.imgbox} src={zimg} alt={'about'} />
          </div>
          
          <div className={styles.lists}>
            <div className={styles.contlist}></div>
            <img className={styles.imgbox} src={zimg2} alt={'about'} />
            <div className={styles.textbox}>
              <span className={styles.detailtitle}>Our Vission</span>
              <span className={styles.detaildes}>
              <ul className={styles.detaildes}>
  <li>To be a leading provider of innovative digital solutions.</li>
  <li>To empower businesses with cutting-edge technology.</li>
  <li>To enhance efficiency, productivity, and growth through our services.</li>
  <li>To drive digital transformation with high-quality websites, systems, and ERP solutions.</li>
  <li>To create long-term value for our clients through reliable and scalable solutions.</li>
</ul></span>
            </div>
          </div>
          {/* <Title mainTitle={'Our Staff Team'} subTitle={""}/>
            <StaffReview/> */}
          <PartnerSlide/>
          {/* <div className={styles.vidbox}>
            <video className={styles.video} src={video2} controls />
            <video className={styles.video1} src={video3} controls />
            </div> */}
        </div>
      </div>
    </>
  );
};

export default About;
