import React from "react";

const MapComponent = () => {
  // New location coordinates for Two Z tech solution and Human Resource in Addis Ababa
  const latitude = 8.997628;
  const longitude = 28.758690;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.4687462949073!2d38.79434837501885!3d9.02093259104009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8514361c2b6b%3A0x1c5a57672b2a5309!2zR3JhY2UgQ2l0eSBNYWxsIHwgTWVnZW5hZ25hIHwg4YyN4Yis4Yi1IOGIsuGJsiDhiJ7hiI0gfCDhiJjhjIjhipPhips!5e0!3m2!1sen!2set!4v1741796465131!5m2!1sen!2set" style={{ border: "0", width: "100%", height: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
    </div>
  );
};

export default MapComponent;