import React, { useEffect, useState } from 'react'
import styles from './PartnerSlide.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay,Navigation  } from 'swiper/modules';
import axios from 'axios';
import { BACKENDURL } from '../helper/Urls';

import pic from '../../assets/awards/abay.png'
import pic2 from '../../assets/awards/addis.png'
import pic3 from '../../assets/awards/world.png'
import pic4 from '../../assets/awards/lion.png'
import pic5 from '../../assets/awards/enatbank.png'
import pic6 from '../../assets/awards/romel.png'
import pic7 from '../../assets/awards/alnur.png'
import partner1 from "../../assets/brands/defence.png";
import partner2 from "../../assets/brands/ethioelectric.png";
import partner3 from "../../assets/brands/hdase.jpg";
import partner4 from "../../assets/brands/kaldis.jpg";
import partner5 from "../../assets/brands/revenue.jpg";
import partner6 from "../../assets/brands/ef.png";
import partner7 from "../../assets/brands/cdav.png";
import partner8 from "../../assets/brands/tnt.png";
import partner9 from "../../assets/brands/emarush.png";
import partner10 from "../../assets/brands/flintstone.png";
import partner11 from "../../assets/brands/jambo.png";
import partner12 from "../../assets/brands/mekelakeya.jpg";
import partner13 from "../../assets/brands/nasew.png";
import partner14 from "../../assets/brands/rubis.jpg";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Title from '../title/Title';
import { Image } from 'antd';

const PartnerSlide = () => {
  
    const Partners=[
        {
          id:1,
          img:partner1,
          name:'John Doe',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        {
          id:2,
          img:partner2,
          name:'John Doe',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'https://www.facebook.com',
          twitter:'https://www.facebook.com',
          instagram:'',
        },
        {
          id:3,
          img:partner3,
          name:'John Doe',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'',
          linkedin:'',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        {
          id:4,
          img:partner4,
          name:'Person four',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        {
          id:5,
          img:partner5,
          name:'person five',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'https://www.facebook.com',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        {
            id:6,
            img:partner6,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:7,
            img:partner7,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:8,
            img:partner8,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          }
          ,
          {
            id:9,
            img:partner9,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          }
          ,
          {
            id:10,
            img:partner10,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:11,
            img:partner11,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:12,
            img:partner12,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:13,
            img:partner13,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:14,
            img:partner14,
            name:'person five',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          }
      ]

const [currentSlideIds, setCurrentSlideIds] = useState([]);
      const [middleItemId, setMiddleItemId] = useState(null);

      const handleSlideChange = (swiper) => {
        const { activeIndex, slides } = swiper;
        const visibleSlideIds = slides
          .filter((slide, index) => index >= activeIndex && index < activeIndex + swiper.params.slidesPerView)
          .map((slide) => parseInt(slide.getAttribute('data-id'), 10));
    
        setCurrentSlideIds(visibleSlideIds);
      };
    
      useEffect(() => {
        if (currentSlideIds.length > 0) {
          const middleIndex = Math.floor(currentSlideIds.length / 2);
          setMiddleItemId(currentSlideIds[middleIndex]);
        } else {
          setMiddleItemId(null);
        }
      }, [currentSlideIds]);
    

  return (
    <div className={styles.cont}>
          <Title mainTitle={'Partner that Trust Us'} subTitle={""}/>
          <div className={styles.list}>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            onSlideChange={handleSlideChange} 
            loop={true}
            breakpoints={{
              '@0.00': {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              '@0.50': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '@0.75': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              '@1.00': {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              '@1.50': {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
            modules={[Autoplay,Navigation ]}
        className={styles.myswiper}
      >
        {Partners.map((l)=>(
            <SwiperSlide className={styles.slide} key={l.id} data-id={l.id}>
            <div className={middleItemId===l.id ?styles.activeitemboxpart:styles.itemboxpart} >
                <Image width={120} height={120} src={l.img} alt={l.name}/>  
            </div>
        </SwiperSlide>
        ))}
        {/* <div className={styles.customNavigation}>
              <FaAngleLeft className={styles.navbtnl}/>
              <FaAngleRight className={styles.navbtnr}/>
          </div> */}
      </Swiper>

          </div>
    </div>
  )
}

export default PartnerSlide