import React, { useEffect } from "react";
import styles from "./awardsPage.module.css";
import PageIntro from "../components/cards/PageIntro";
import zimg from "../assets/gallery.jpg";
import award from "../assets/awards/award.jpg";
import AwardsSlide from "../components/cards/AwardsSlide";

const AwardsPage = () => {
  useEffect(() => {
    document.title = " Awards";
  }, []);

  return (
    <>
      {/* <PageIntro text={"Awards"} img={zimg} /> */}
      <div className={styles.awardimg}>
        <img src={award}/>
        <div className={styles.awarddes}>
          <p>Our company has been recognized for its commitment to excellence in digital solutions. Over the past two years, we have earned awards and certifications that highlight our expertise in website development, system integration, and ERP solutions. These achievements reflect our dedication to delivering high-quality, innovative, and reliable services that meet industry standards and client expectations.</p>
        </div>
      </div>
      <AwardsSlide/>
      <div className={styles.cont}>
        <div className={styles.box}>
          
        </div>
      </div>
    </>
  );
};

export default AwardsPage;
