import React, { useEffect, useState } from 'react'
import styles from './ServiceSlide.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay,Navigation  } from 'swiper/modules';
import axios from 'axios';
import { BACKENDURL } from '../helper/Urls';

import pic from '../../assets/awards/abay.png'
import pic2 from '../../assets/awards/addis.png'
import pic3 from '../../assets/awards/world.png'
import pic4 from '../../assets/awards/lion.png'
import pic5 from '../../assets/awards/enatbank.png'
import pic6 from '../../assets/awards/romel.png'
import pic7 from '../../assets/awards/alnur.png'
import training1 from "../../assets/services/cleaningv.jpg";
import training2 from "../../assets/projects/solidier.jpg";
import training3 from "../../assets/services/cleaningv2.jpeg";
import training4 from "../../assets/services/cleaningv3.jpg";
import training5 from "../../assets/projects/solidier6.jpg";
import training7 from "../../assets/services/cleaning.jpeg";


import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Title from '../title/Title';
import { Image } from 'antd';

const TrainingSlide = () => {
  
    const Partners=[
        {
          id:1,
          img:training1,
          name:'ERP System Development',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        {
          id:2,
          img:training2,
          name:'Web Development Solutions',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'https://www.facebook.com',
          twitter:'https://www.facebook.com',
          instagram:'',
        },
        {
          id:3,
          img:training3,
          name:'Mobile Applications',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'',
          linkedin:'',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        {
          id:4,
          img:training4,
          name:'Software Solutions',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        {
          id:5,
          img:training5,
          name:'Company Profiles',
          role:'Two Z tech solution',
          desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
          facebook:'https://www.facebook.com',
          linkedin:'https://www.facebook.com',
          twitter:'https://www.facebook.com',
          instagram:'https://www.facebook.com',
        },
        
          {
            id:6,
            img:training7,
            name:'Web and App Maintenance',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:7,
            img:training7,
            name:'Consulting Services',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:8,
            img:training7,
            name:'Security Solutions',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:9,
            img:training7,
            name:'Cloud-Based Solutions',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          },
          {
            id:10,
            img:training7,
            name:'Training and Support',
            role:'Two Z tech solution',
            desc:'ipsum dolor sit amet consectetur adipisicing elit. Error dolorem sequi liem, ipsa aspernatur architecto corporis odio!',
            facebook:'https://www.facebook.com',
            linkedin:'https://www.facebook.com',
            twitter:'https://www.facebook.com',
            instagram:'https://www.facebook.com',
          }
      ]

const [currentSlideIds, setCurrentSlideIds] = useState([]);
      const [middleItemId, setMiddleItemId] = useState(null);

      const handleSlideChange = (swiper) => {
        const { activeIndex, slides } = swiper;
        const visibleSlideIds = slides
          .filter((slide, index) => index >= activeIndex && index < activeIndex + swiper.params.slidesPerView)
          .map((slide) => parseInt(slide.getAttribute('data-id'), 10));
    
        setCurrentSlideIds(visibleSlideIds);
      };
    
      useEffect(() => {
        if (currentSlideIds.length > 0) {
          const middleIndex = Math.floor(currentSlideIds.length / 2);
          setMiddleItemId(currentSlideIds[middleIndex]);
        } else {
          setMiddleItemId(null);
        }
      }, [currentSlideIds]);
    

  return (
    <div className={styles.cont}>
          <Title mainTitle={'Products and Service'} subTitle={""}/>
          <div className={styles.list}>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            onSlideChange={handleSlideChange} 
            loop={true}
            breakpoints={{
              '@0.00': {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              '@0.50': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '@0.75': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              '@1.00': {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              '@1.50': {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
            modules={[Autoplay,Navigation ]}
        className={styles.myswiper}
      >
        {Partners.map((l)=>(
            <SwiperSlide className={styles.slide} key={l.id} data-id={l.id}>
            <div className={middleItemId===l.id ?styles.activeitemboxpart:styles.itemboxpart} >
                <div className={styles.name}>{l.name}</div>
            </div>
        </SwiperSlide>
        ))}
        {/* <div className={styles.customNavigation}>
              <FaAngleLeft className={styles.navbtnl}/>
              <FaAngleRight className={styles.navbtnr}/>
          </div> */}
      </Swiper>

          </div>
    </div>
  )
}

export default TrainingSlide