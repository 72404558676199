import React, { useEffect, useState } from 'react'
import styles from './productslide.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay,Navigation  } from 'swiper/modules';
import axios from 'axios';
import { BACKENDURL } from '../helper/Urls';

import pic from '../../assets/awards/rubis.jpg'
import pic2 from '../../assets/awards/nasew.jpg'
import pic3 from '../../assets/awards/astara.jpg'
import pic4 from '../../assets/awards/jigdan.jpg'
import pic5 from '../../assets/awards/ethelco.jpg'
import pic6 from '../../assets/awards/jambo.jpg'
import pic7 from '../../assets/awards/metre.jpg'
import pic8 from '../../assets/awards/city.jpg'

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Title from '../title/Title';
import { Image } from 'antd';

const AwardsSlide = () => {
    const [galleryData, setgalleryData] = useState([]);
  
    useEffect(() => {
      const fetchAwardData = async () => {
        try {
          const res = await axios.get(`${BACKENDURL}/award/all`);
          setgalleryData(res.data.all);
        } catch (error) {
          console.error('Error fetching slide data:', error);
        }
      };
  
      fetchAwardData();
    }, []); 
      // const galleryData=[
      //     {
      //       "id": 1,
      //       "companyName": "Rubis",
      //       "description": "Our security company provides comprehensive security solutions tailored specifically for specialized hospitals. With a focus on ensuring the safety and protection of patients, staff, and sen",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic
      //     },
      //     {
      //       "id": 2,
      //       "companyName": "Nasew Construction",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic2,
      //       "description": "We provide comprehensive security solutions for Enat Bank, ensuring the safety of customer data and financial transactions. Our advanced systems, including real-time fraud detection, data enc"
      //     },
      //     {
      //       "id": 3,
      //       "companyName": "Astara Hotel",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic3,
      //       "description": "Our security services for Abay Bank focus on safeguarding their clients and assets. With state-of-the-art technology, we provide real-time fraud detection, data protection, and continuous sur"
      //     },
      //     {
      //       "id": 4,
      //       "companyName": "Jigdan College",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic4,
      //       "description": "Our security company provides top-notch security services exclusively tailored for NIB Bank. With a focus on safeguarding the bank's premises, assets, and personnel, we offer a comprehensive range of security solutions to ensure the highest level of protection and peace of mind for NIB Bank and its stakeholders. Our dedicated team of trained professionals is committed to maintaining a secure environment through advanced security measures, cutting-edge technology, and vigilant monitoring. Trust us to deliver proactive security services that meet the unique needs and standards of NIB Bank, safeguarding its operations and reputation effectively."
      //     },
      //     {
      //       "id": 5,
      //       "companyName": "Ethiopian Electronics",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic5,
      //       "description": "Our security company specializes in providing tailored security services for Abysinia Bank. With a strong focus on protecting the bank's assets, employees, and customers, we offer a comprehensive suite of security solutions designed to meet the unique needs of financial institutions. Our team of trained professionals is dedicated to maintaining a safe and secure environment through the implementation of advanced security technologies, rigorous protocols, and round-the-clock monitoring. Count on us to deliver proactive security measures that align with the high standards and specific requirements of Abysinia Bank, ensuring the utmost protection for its operations and ensuring peace of mind for all stakeholders."
      //     },
      //     {
      //       "id": 6,
      //       "companyName": "Jambo Global",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic6,
      //       "description": "Our team of highly trained professionals is committed to ensuring a secure environment through the deployment of cutting-edge security technologies, strict security protocols, and constant vigilance. We work closely with Oromia Bank to deliver proactive security measures that are in line with industry standards and regulatory requirements, thereby safeguarding the bank's operations and fostering a sense of trust and safety among its clients and staff. Rest assured that with our expertise and dedication, Oromia Bank can operate with confidence and peace of mind."
      //     },
      //     {
      //       "id": 7,
      //       "companyName": "Meter Security And Sanitation",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic7,
      //       "description": "Our team of highly trained professionals is committed to ensuring a secure environment through the deployment of cutting-edge security technologies, strict security protocols, and constant vigilance. We work closely with Oromia Bank to deliver proactive security measures that are in line with industry standards and regulatory requirements, thereby safeguarding the bank's operations and fostering a sense of trust and safety among its clients and staff. Rest assured that with our expertise and dedication, Oromia Bank can operate with confidence and peace of mind."
      //     },
      //     {
      //       "id": 7,
      //       "companyName": "City Government of Addis Ababa",
      //       "type":'Letter',
      //       "link":'https://www.zaahirahtravels.com',
      //       "file":'https://www.zaahirahtravels.com',
      //       "pic": pic8,
      //       "description": "Our team of highly trained professionals is committed to ensuring a secure environment through the deployment of cutting-edge security technologies, strict security protocols, and constant vigilance. We work closely with Oromia Bank to deliver proactive security measures that are in line with industry standards and regulatory requirements, thereby safeguarding the bank's operations and fostering a sense of trust and safety among its clients and staff. Rest assured that with our expertise and dedication, Oromia Bank can operate with confidence and peace of mind."
      //     },
          
      //   ]
      const [currentSlideIds, setCurrentSlideIds] = useState([]);
      const [middleItemId, setMiddleItemId] = useState(null);

      const handleSlideChange = (swiper) => {
        const { activeIndex, slides } = swiper;
        const visibleSlideIds = slides
          .filter((slide, index) => index >= activeIndex && index < activeIndex + swiper.params.slidesPerView)
          .map((slide) => parseInt(slide.getAttribute('data-id'), 10));
    
        setCurrentSlideIds(visibleSlideIds);
      };
    
      useEffect(() => {
        if (currentSlideIds.length > 0) {
          const middleIndex = Math.floor(currentSlideIds.length / 2);
          setMiddleItemId(currentSlideIds[middleIndex]);
        } else {
          setMiddleItemId(null);
        }
      }, [currentSlideIds]);
    

  // const getCurrentVisibleItemIds = () => {
  //   return currentSlideIds;
  // };

  return (
    <div className={styles.cont}>
          <Title mainTitle={'Awards and Certficates'} subTitle={"CHECK OUT OUR Awards and Certficates"}/>
          <div className={styles.list}>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            onSlideChange={handleSlideChange} 
            loop={true}
            breakpoints={{
              '@0.00': {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              '@0.50': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '@0.75': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              '@1.00': {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              '@1.50': {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
            modules={[Autoplay,Navigation ]}
        className={styles.myswiper}
      >
        {galleryData.map((l)=>(
            <SwiperSlide className={styles.slide} key={l.id} data-id={l.id}>
            <div className={middleItemId===l.id ?styles.activeitembox:styles.itembox} >
                <Image width={120} height={120} src={`${BACKENDURL}/uploads/photo/${l.attachment}`} alt={l.name}/>
                {/* <Image width={120} height={120} src={l.pic} alt={l.name}/> */}
                <div className={styles.itemtxt}>
                    <span className={styles.itemtitle}>{l.type}</span>
                    <span className={styles.itemdes}>{l.companyName}</span>
                </div>
            </div>
            
        </SwiperSlide>
        ))}
        {/* <div className={styles.customNavigation}>
              <FaAngleLeft className={styles.navbtnl}/>
              <FaAngleRight className={styles.navbtnr}/>
          </div> */}
      </Swiper>

          </div>
    </div>
  )
}

export default AwardsSlide