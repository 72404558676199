import React from 'react'
import styles from './Message.module.css'
import { FaRegMessage } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Message = () => {
  return (
    <div className={styles.message} ><NavLink to={'/contact'} className={styles.navlink}><FaRegMessage color={'white'} size={'1.3rem'}/></NavLink></div>
  )
}

export default Message