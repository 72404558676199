import ProductCard from './ProductCard'
import styles from './servicereview.module.css'
import { NavLink } from 'react-router-dom'
import pic from '../../assets/services/technology.jpg'
import pic4 from '../../assets/services/training4.jpg'
import pic2 from '../../assets/services/It.webp'
import pic3 from '../../assets/services/webdesign.webp'
import Title from '../title/Title'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKENDURL } from '../helper/Urls';

const ServiceReview = () => {
  
  const scrollTop=()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  // const [servies, setservies] = useState([]);

  // useEffect(() => {
  //   const fetchServiceData = async () => {
  //     try {
  //       const res = await axios.get(`${BACKENDURL}/service/all`);
  //       setservies(res.data.all);
  //     } catch (error) {
  //       console.error('Error fetching slide data:', error);
  //     }
  //   };

  //   fetchServiceData();

  //   // Clean-up function
  //   return () => {
  //     // Any clean-up code can go here
  //   };
  // }, []);

   const servies = [
       {
         "id": 1,
         "title": "Technology solution",
         "pic": pic,
         "description": "We provide cutting-edge technology solutions designed to streamline operations, enhance efficiency, and drive business growth. From website development and ERP systems to customized software and IT consulting, our solutions are tailored to meet the unique needs of your business. Whether you're looking to improve workflows, automate processes, or strengthen your digital presence, we deliver scalable, secure, and innovative technology that helps you stay ahead in a competitive market."
       },
       {
         "id": 2,
         "title": "It Service",
         "pic": pic2,
         "description": "We offer a full range of IT services designed to keep your business running smoothly and securely. From system integration and cloud solutions to network security and IT support, we provide reliable and scalable solutions tailored to your needs. Our team ensures seamless technology operations, minimizing downtime and enhancing efficiency. Whether you need infrastructure management, cybersecurity, or ongoing tech support, we deliver innovative IT services to help your business thrive in the digital era."
       },
       {
         "id": 3,
         "title": "Web & App Design",
         "pic": pic3,
         "description": "We create visually stunning, user-friendly websites and mobile applications that deliver seamless digital experiences. Our designs are not only aesthetically appealing but also optimized for performance, responsiveness, and functionality. Whether you need a sleek business website, an engaging e-commerce platform, or a feature-rich mobile app, we blend creativity with technology to bring your vision to life."
       },
     ];
  return (
    <div className={styles.cont}>
          <Title mainTitle={'Our Service'} subTitle={"Create your own paradiset get inspired. See below"}/>
          <div className={styles.lists}>
            {servies.slice(0, 3).map((l)=><ProductCard key={l.id} {...l}/>)}
          </div>
          <div className={styles.moreservice}><NavLink to={'/service'} onClick={scrollTop}>More Service</NavLink></div>
        </div>
  )
}

export default ServiceReview