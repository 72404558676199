import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

const FAQ = () => {
  return (
    <div style={{ maxWidth: "90%", margin: "auto", padding: "20px" }}>
      <h2>Frequently Asked Questions</h2>
      <Collapse accordion >
        <Panel header="What services does Twoz Software Solution provide?" key="1">
          <p>Twoz Software Solution specializes in developing websites, ERP systems, mobile apps, and custom software solutions. We also offer consulting services for businesses looking to enhance their digital presence and streamline operations.</p>
        </Panel>
        <Panel header="How experienced is Twoz Software Solution in software development?" key="2">
          <p>With over two years of experience, our team has successfully delivered websites, ERP solutions, and software systems for various industries, including security services and cleaning businesses.</p>
        </Panel>
        <Panel header="Can Twoz Software Solution customize software based on my business needs?" key="3">
          <p>Absolutely! We develop tailored solutions to fit your business requirements. Whether you need a custom ERP system, a specialized web platform, or a mobile app, we ensure the software aligns with your workflow and goals.</p>
        </Panel>
        <Panel header="How can I get started with Twoz Software Solution?" key="4">
          <p> You can reach out to us via our website, email, or phone. We will schedule a consultation to understand your needs and provide a customized solution to help your business grow.

Let me know if you’d like any modifications!</p>
        </Panel>
      </Collapse>
      <h2>Services FAQs</h2>
      <Collapse accordion >
        <Panel header="What types of services does Twoz Software Solution provide?" key="1">
          <p>We offer website development, ERP system development, mobile app development, and IT consulting services. Our goal is to help businesses enhance efficiency through customized digital solutions.</p>
        </Panel>
        <Panel header="Do you provide ongoing support and maintenance for your software solutions?" key="2">
          <p>Yes! We offer post-development support, system upgrades, and maintenance services to ensure your software remains secure, up-to-date, and fully functional.</p>
        </Panel>
        <Panel header="Can you integrate your software solutions with our existing systems?" key="3">
          <p>Yes, we specialize in seamless integration with existing systems, whether it's an accounting tool, HR system, or other third-party software.</p>
        </Panel>
        <Panel header="How long does it take to develop a custom ERP system?" key="4">
          <p>Yes, we prioritize health and the environment. We use eco-friendly, non-toxic, and safe cleaning products for all of our services.</p>
        </Panel>
      </Collapse>
      <h2>Products FAQs</h2>
      <Collapse accordion >
        <Panel header="Do you offer a ready-made ERP system, or is it custom-built?" key="1">
          <p>We provide both options. Our ready-made ERP system is designed for quick deployment, while our custom ERP solutions are tailored to fit your specific business processes.</p>
        </Panel>
        <Panel header="Is your security service management system cloud-based?" key="2">
          <p>Yes, our security service ERP system is cloud-based, ensuring accessibility from anywhere with high security and real-time data updates.</p>
        </Panel>
        <Panel header="Can you integrate your software solutions with our existing systems?" key="3">
          <p>Yes, we specialize in seamless integration with existing systems, whether it's an accounting tool, HR system, or other third-party software.</p>
        </Panel>
        <Panel header="Can your HR and payroll software handle large organizations?" key="4">
          <p>Yes, our HR and payroll system is scalable and can manage businesses of all sizes, from small startups to large enterprises, with advanced features like employee records, attendance tracking, and automated payroll processing.</p>
        </Panel>
      </Collapse>
    </div>
  );
};

export default FAQ;
