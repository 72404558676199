import React, { useEffect, useState } from "react";
import styles from "./productshow.module.css";
import whopic from "../../assets/imgs/training5.jpg";
import whatpic from "../../assets/imgs/training2.jpg";
import whypic from "../../assets/imgs/training3.jpg";
import howpic from "../../assets/imgs/training4.jpg";

import { FaDotCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const ProductShow = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [selectedItem,setSelectedItem]=useState(1)

  // useEffect(() => {
  //   setIsVisible(true);
  // }, [selectedItem]);

  useEffect(() => {
    setIsVisible(false);
    const interval = setInterval(() => {
      setSelectedItem((prevItem) => (prevItem === minerals.length ? 1 : prevItem + 1));
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setIsVisible(true);
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [selectedItem]);

  const minerals = [
    {
      id: 1,
      name: 'Who We Are',
      img:whopic,
      description: "At Two Z, We are a technology-driven company specializing in website development, ERP systems, and tailored digital solutions. With a focus on innovation and efficiency, we help businesses streamline operations and enhance their online presence.",
    },
    {
      id: 2,
      name: 'What we Do',
      img:whatpic,
      description: "We specialize in building high-quality websites, powerful ERP systems, and customized digital solutions tailored to your business needs. Beyond development, we offer expert consulting to help businesses optimize operations, improve efficiency, and stay ahead in the digital landscape.",
    },
    {
      id: 3,
      name: 'Why Us',
      img:whypic,
      description: "We combine innovation, expertise, and a customer-first approach to deliver exceptional digital solutions. Our team specializes in building high-quality websites, ERP systems, and tailored business solutions designed for efficiency and growth.",
    },
    {
      id: 4,
      name: "How We're Different",
      img:howpic,
      description: "We don’t just build websites and ERP systems—we create tailored digital solutions that drive real business growth. Our approach is rooted in understanding your unique needs, ensuring every solution is scalable, secure, and efficient. Unlike generic service providers, we offer expert consulting to help you make informed technology decisions.",
    },
  ];

  return (
    <div className={styles.box}>
      <div className={styles.cont}>
      <div className={styles.slider}>
        <div className={styles.lines}>{minerals.map(l=><span key={l.id} style={{background:selectedItem >= l.id?'var(--fcolor)':'transparent'}} className={styles.line}></span>)}</div>
        <div className={styles.nums}>{minerals.map(l=><span key={l.id} style={{background:selectedItem===l.id?'var(--fcolor)':'transparent',color:selectedItem===l.id?'white':'var(--fcolor)'}} onClick={()=>setSelectedItem(l.id)} className={styles.rolnum}><FaDotCircle/></span>)}</div>
      </div>
      <div className={styles.contentbox}>
      <div className={styles.content}>
        <span className={isVisible ? styles.title:styles.hiddentitle}>{minerals.find((mineral) => mineral.id === selectedItem).name}</span>
        <span className={isVisible ? styles.description : styles.hiddendes}>{minerals.find((mineral) => mineral.id === selectedItem).description}</span>
      </div>
      <div className={styles.imgs}><img className={isVisible ? styles.showimg:styles.hideimg} src={minerals.find((mineral) => mineral.id === selectedItem).img} alt="mineral"/></div>
      </div>
    </div>
    </div>
  );
};

export default ProductShow;
